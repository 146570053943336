

body {
   /** Colors **/
  --color-teal:  #58bebf;
  --color-blue: #355c71;
  --color-bright-blue: #3e6c83;
  --color-dark: #333333;
  --color-light-gray: #6a666a;
  --color-light: #dddddd;
  --color-white: #fff;

  overflow-x: hidden;
  margin: 0;
  background-color: var(--color-dark);
  color: var(--color-white);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 12pt;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3 {
  color: var(--color-teal);
  @media screen and (max-width: 768px) {
    font-size: 16pt;
  }
}

h1.main-header {
  padding-top: 10px;
}

div.section {
  margin: 10px;
  background-color: var(--color-blue);
  border-radius: 10px;
  padding: 15px;
  color: var(--color-white);
  @media screen and (min-width: 964px) {
    min-height: 260px;
  };
}

div.section-wrapper, div.eval-section-wrapper {
  display: flex;
  justify-content: center;
  flex: 0 0 1;
  background-color: var(--color-dark);
  padding: 20px;
  flex-direction: row;
  @media screen and (max-width: 964px) {
		flex-direction: column;
	}
}

div.eval-section-wrapper .section {
  @media screen and (min-width: 964px) {
		width: 50%;
	}
}


div.section h3 {
  color: var(--color-teal);;
  
  @media screen and (min-width: 964px) {
		min-height: 65px;
	}
}

.nav.default-nav { 
  display: none !important;
}

.footer {
  background-color: var(--color-blue);
}

.footer-wrapper {
  max-width: 964px;
  padding: 20px;
  margin: 0 auto;
  padding-bottom: 0;
  display: flex;
  flex-direction:row;
  justify-content: space-evenly;
  align-items: flex-end;
}

.footer-image {
  max-width: 300px;
  position: relative;
  bottom: -6px;
  @media screen and (max-width: 768px) {
		display: none;
	}
}
.footer p {
  max-width: 400px;
  margin-right: 20px;
  @media screen and (max-width: 768px) {
		max-width: 100%;
	}
}

.footer .dark-button {
  @media screen and (max-width: 768px) {
		width: 95%;
	}
}

.blue-button {
  padding: 10px 20px;
  margin: 10px 0;
  color: var(--color-white);
  background-color: var(--color-blue);
  text-decoration: none;
  display: inline-block;
  width: auto;
  text-align: center;
  border: 0;
  border-bottom: 3px solid var(--color-teal);
}

.dark-button {
  padding: 10px 20px;
  margin: 10px 0;
  color: var(--color-white);
  background-color: var(--color-dark);
  text-decoration: none;
  display: inline-block;
  width: auto;
  text-align: center;
  border: 0;
  border-bottom: 3px solid var(--color-teal);
  width: 80%;
}

.bottom-footer {
  padding: 12px;
  font-size: 12pt;
  display: flex; 
  flex-direction: row;
  justify-content: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
}

.bottom-footer span {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.bottom-footer a {
  color: inherit;
  text-decoration: none;
}

.not-found p {
  padding: 0 20px;
}

.not-found {
  margin: 0 auto;
  text-align: center;
}

.not-found a {
  color: var(--color-teal);
  font-weight: 500;
}

.mobile-menu {
  display: none;
  width: 50vw;
  background: var(--color-dark);
  height: auto;
  display: block;
  position: absolute;
  z-index: 99;
  right: 0;
}
.mobile-menu a {
  display: block;
  color: var(--color-white);
  text-decoration: none;
  padding: 20px;
  border-bottom: 2px solid var(--color-light-gray);
}

.spacing {
  border-bottom: 2px solid var(--color-bright-blue);
  padding: 10px;
  font-weight: bold;
}

.tab-content ul {
  padding: 20px;
}
.tab-content li {
  list-style: none;
  padding: 10px 0;
}

.tab-content li b {
  color: var(--color-teal);;
  font-weight: bold;
  margin-right: 10px;
}

.tab-list-item.tab-list-active {
  color: var(--color-bright-blue);;
  font-weight: bold;
}

.tab-content h3 {
  margin-top: 0px;
  padding-top: 0px;
}

.ces-banner-image {
  position: relative;
  bottom: -7px;
  height: 100%;
  width: 100%;
  @media screen and (max-width: 964px) {
		display: none;
	}
}

.place-card {
  display: none !important;
}

#contact-form input, #contact-form textarea {
  padding: 5px;
  margin: 10px 5px;
  font-size: 12pt;
  border: 0;
    border-bottom-width: 0px;
    border-bottom-style: none;
    border-bottom-color: currentcolor;
  border-bottom: 2px solid var(--color-teal);
  display: inline-block;
}

#contact-form textarea {
  max-width: 452px;
  min-height: 100px;
  max-height: 400px;
  @media screen and (max-width: 967px) {
    width: 97%;
    max-width: none;
    min-width: none;
  }
}

#contact-form input[type="text"] {
  width: 50%;
}

#contact-form input[type="submit"] {
  background-color: var(--color-blue);
  border-bottom: 5px solid var(--color-teal);
}

#contact-form input::placeholder, #contact-form textarea::placeholder {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

#contact-form {
  @media screen and (max-width: 964px) {
    width: calc(100% - 40px);  
  }
}
.contact-info {
  display: flex;
  padding-bottom: 0;
  border-top: 4px solid var(--color-teal);
  @media screen and (max-width: 964px) {
		flex-direction: column;
    flex-flow: column-reverse;
	}
}

.ces-info {
  border-bottom: 1px solid var(--color-light) !important;
  @media screen and (max-width: 964px) {
		flex-direction: column;
    border-bottom: none !important;
  }
}

.ces-info div {
  padding: 0 20px;
  padding-bottom: 20px;
  @media screen and (min-width: 964px) {
    width: 50%;
  }
  @media screen and (max-width: 964px) {
    border-bottom: 1px solid var(--color-light) !important;
  }
} 

.dcp-info {
  border-bottom: 1px solid var(--color-light) !important;
  @media screen and (max-width: 964px) {
		flex-direction: column;
    border-bottom: none !important;
  }
}

.dcp-info div {
  padding: 0 20px;
  padding-bottom: 20px;
  @media screen and (max-width: 964px) {
    border-bottom: 1px solid var(--color-light) !important;
  }
} 

a {
  color: var(--color-teal);
}

.office-info a {
  color: var(--color-teal);
  font-weight: bold;
}
.office-info h3 {
  margin-bottom: 5px;
}
.office-info li {
  list-style: none;
  padding: 0;
}
.office-info {
  padding: 4px;
}

#downloads {
  margin: 10px 0;
  padding: 10px 20px;
  color: var(--color-dark);
}

.logo {
  max-width: 300px;
  width: 100%;
  margin: 0 10px;
}

.pro-list {
  padding: 15px;
}

.pro-list > li {
  padding: 10px 5px;
}

.pro-list ul li {
  padding: 5px;
}

.pro-image {
  @media screen and (max-width: 964px) {
    display: none;
  }
}

.rrr-blurb {
  flex-direction: column;
}

.rrr-blurb div.blurb {
  background-color: var(--color-bright-blue);
  color: var(--color-white);
  padding: 15px 40px;
  margin: 20px;
  border-radius: 20px;
}

.rrr-blurb img {
  width: 50px;
  filter: sepia(20%);
  filter: hue-rotate(80deg);
}

.rrr-blurb .quote-bottom {
  transform: rotate(180deg);
  float: right;
}

.quiz-form input[type="checkbox"] {
  width: 20px;
  height: 20px;
  filter: hue-rotate(-40deg) opacity(75%);
}

.quiz-form ul li {
  list-style: none;
  padding: 10px 0;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: #000000a6;
  display: flex;
}
.modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 4px solid var(--color-teal);
  padding: 20px;
  border-radius: 20px;
  background-color: var(--color-white);
  @media screen and (max-width: 768px) {
    width: 80%;
  }
}
.modal button {
  float: right;
  height: 30px;
  width: 30px;
  background: var(--color-teal);
  color: var(--color-dark);
  border: none;
  border-radius: 5px;
}
.lds-ring {
  display: flex;
  position: relative;
  width: 100%;
  height: 504px;
  justify-content: center;
  align-items: center;
  border-bottom: 4px solid var(--color-teal);
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.primary-reading li {
  padding: 10px 0;
}

.secondary-reading {
  margin-top: 10px;
}

.secondary-reading li {
  list-style: none;
  padding: 0;
}

.primary-reading:first-of-type {
  margin-bottom: 0px;
}

.footer-content {
  display: flex;
  justify-content: center;
  flex-direction: row;
  @media screen and (max-width: 764px) {
    flex-direction: column;
  }
}

.footer-buttons {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
